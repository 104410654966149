import React, {Suspense, useEffect} from 'react'
import ThemeProvider from '@jobmatchme/ui/es/provider/ThemeProvider'
import CloseIcon from '@jobmatchme/ui/es/components/icons/Close/index'
import TrackingProvider from '@jobmatchme/tracking/es/provider/Tracking'
import RouteProvider, {Route} from '@jobmatchme/routing/es/provider/External'
import DriverPage from '@jobmatchme/landing-page-components/es/components/truck/pc/DriverPage'
import SuccessStoryPage from '@jobmatchme/landing-page-components/es/components/truck/bc/SuccessStoryPage/lazy'
import SuccessStoriesList from '@jobmatchme/landing-page-components/es/components/truck/bc/SuccessStoriesList/lazy'
import LegalConditions from '@jobmatchme/landing-page-components/es/components/truck/common/LegalConditions/lazy'
import NotFoundPage from '@jobmatchme/landing-page-components/es/components/truck/common/NotFoundPage/lazy'
import LegalImprint from '@jobmatchme/landing-page-components/es/components/truck/common/LegalImprint/lazy'
import ErrorDialog from '@jobmatchme/landing-page-components/es/components/truck/common/ErrorDialog/lazy'
import LegalPage from '@jobmatchme/landing-page-components/es/components/truck/common/Legal/lazy'
import Faq from '@jobmatchme/landing-page-components/es/components/truck/common/Faq/lazy'
import {getUrlLocale} from '@jobmatchme/utils/es/getUrlParam'
import ErrorBoundary from '@jobmatchme/ui/es/Helper/Error'
import {IntlProvider} from 'react-intl'
import packageVersions from '../packageVersions.json'
import IconButton from '@material-ui/core/IconButton'
import {SnackbarProvider} from 'notistack'

import 'bootstrap/dist/css/bootstrap.css'

const RedirectGk = () => {
  useEffect(() => {
    window.location.replace(
      'https://jobmatch.me/de-de/arbeitgeber/lkw-fahrer-finden/'
    )
  }, [])
  return <></>
}

const RedirectPk = () => {
  useEffect(() => {
    window.location.replace('https://www.jobmatch.me/de/jobs/fahrer/suche/')
  }, [])
  return <></>
}

const App = ({router, location}) => {
  const notistackRef = React.createRef()
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <Suspense fallback={''}>
      {/* Emergency suspence, so that the app does not crash on us if one is missing further down */}
      <ThemeProvider variant="pc">
        <IntlProvider locale={getUrlLocale()} defaultLocale="de">
          <TrackingProvider>
            <SnackbarProvider
              maxSnack={5}
              ref={notistackRef}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              action={key => (
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={onClickDismiss(key)}
                >
                  <CloseIcon color="white" />
                </IconButton>
              )}
            >
              <ErrorBoundary errorDialog={ErrorDialog}>
                <RouteProvider
                  router={router}
                  locale={getUrlLocale()}
                  notFoundPage={NotFoundPage}
                  packageVersions={packageVersions}
                  location={location}
                >
                  <Route
                    id="root"
                    path={{
                      de: '/',
                      at: '/',
                      pl: '/'
                    }}
                    element={<DriverPage />}
                  />
                  <Route
                    exact
                    id="bc"
                    path={{
                      de: '/arbeitgeber/',
                      at: '/arbeitgeber/',
                      pl: '/pracodawca/'
                    }}
                    element={<RedirectGk />}
                  />
                  <Route
                    id="bc-success-story"
                    path={{
                      de: '/erfahrungen/:successStory',
                      at: '/erfahrungen/:successStory',
                      pl: '/doswiadczenie/:successStory'
                    }}
                    element={<SuccessStoryPage />}
                  />
                  <Route
                    id="bc-success-story-list"
                    path={{
                      de: '/erfahrungen/',
                      at: '/erfahrungen/',
                      pl: '/doswiadczenie/'
                    }}
                    element={<SuccessStoriesList />}
                  />
                  <Route
                    staticPath
                    exact
                    id="pcreg"
                    path={{
                      de: '/fahrer/jobsuche/',
                      at: '/fahrer/jobsuche/',
                      pl: '/kierowca/szukaj-pracy/'
                    }}
                  />
                  <Route
                    staticPath
                    exact
                    id="pcregtruck"
                    path={{
                      de: '/fahrer/registrierung/',
                      at: '/fahrer/registrierung/',
                      pl: '/kierowca/szukaj-pracy/rejestracja/'
                    }}
                  />
                  <Route
                    exact
                    id="pcfunnel"
                    path={{
                      de: '/fahrer/jobsuche/*',
                      at: '/fahrer/jobsuche/*',
                      pl: '/kierowca/szukaj-pracy/*'
                    }}
                    element={<RedirectPk />}
                  />
                  <Route
                    exact
                    id="pcregistration"
                    path={{
                      de: '/fahrer/registrierung/*',
                      at: '/fahrer/registrierung/*',
                      pl: '/kierowca/szukaj-pracy/rejestracja/*'
                    }}
                    element={<RedirectPk />}
                  />
                  <Route
                    id="imprint"
                    path={{
                      de: '/impressum/',
                      at: '/impressum/',
                      pl: '/odcisk/'
                    }}
                    element={<LegalImprint />}
                  />
                  <Route
                    id="legal"
                    path={{
                      de: '/nutzungsbedingungen/',
                      at: '/nutzungsbedingungen/',
                      pl: '/warunkikorzystania/'
                    }}
                    element={<LegalConditions />}
                  />
                  <Route
                    id="privacy"
                    path={{
                      de: '/datenschutz/',
                      at: '/datenschutz/',
                      pl: '/politykaprywatnosci/'
                    }}
                    element={<LegalPage />}
                  />
                  <Route
                    id="faq"
                    path={{de: '/faq/', at: '/faq/', pl: '/faq/'}}
                    element={<Faq />}
                  />
                </RouteProvider>
              </ErrorBoundary>
            </SnackbarProvider>
          </TrackingProvider>
        </IntlProvider>
      </ThemeProvider>
    </Suspense>
  )
}
export default React.memo(App)
