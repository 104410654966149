"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReloadOnVersionChange = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _semverCompare = _interopRequireDefault(require("semver-compare"));

var _reactRouterDom = require("react-router-dom");

var UPDATE_INTERVAL = 1000 * 60 * 60;

var fetchVersions = function fetchVersions(packageName) {
  var fetchHeaders = new Headers();
  fetchHeaders.append('Content-Type', 'application/json');
  var fetchInit = {
    method: 'GET',
    headers: fetchHeaders,
    mode: 'cors',
    cache: 'no-store'
  };
  var versionsRequest = new Request("https://s3.amazonaws.com/cf.".concat(process.env.RAZZLE_STAGE, ".versions.truck-jobs.com/").concat(packageName, ".versions.json"));
  return fetch(versionsRequest, fetchInit).then(function (response) {
    return response.json();
  });
};

var ReloadOnVersionChange = function ReloadOnVersionChange(_ref) {
  var packagesWithRoutes = _ref.packagesWithRoutes,
      packageVersions = _ref.packageVersions,
      children = _ref.children;
  var location = (0, _reactRouterDom.useLocation)();

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      intervalId = _useState2[0],
      setIntervalId = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
      packagesWithNewVersion = _useState4[0],
      setPackagesWithNewVersion = _useState4[1];

  var _useState5 = (0, _react.useState)([]),
      _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
      packagesWithNewStableVersion = _useState6[0],
      setPackagesWithNewStableVersion = _useState6[1];

  (0, _react.useEffect)(function () {
    packagesWithNewVersion.forEach(function (pck) {
      packagesWithRoutes[pck].forEach(function (route) {
        if (location.pathname.search(route) > 0) {
          setPackagesWithNewVersion(packagesWithNewVersion.filter(function (elem) {
            return elem !== pck;
          }));
          window.location.reload(true);
        }
      });
    });
  }, [location, packagesWithNewVersion, packagesWithRoutes]);
  (0, _react.useEffect)(function () {
    packagesWithNewStableVersion.forEach(function (pck) {
      packagesWithRoutes[pck].forEach(function (route) {
        if (location.pathname.search(route) > 0) {
          setPackagesWithNewStableVersion(packagesWithNewStableVersion.filter(function (elem) {
            return elem !== pck;
          }));
          window.location.reload(true);
        }
      });
    });
  }, [location, packagesWithNewStableVersion, packagesWithRoutes]);
  (0, _react.useEffect)(function () {
    var reloadVersions = function reloadVersions() {
      var packages = Object.keys(packagesWithRoutes);
      return setInterval(function () {
        packages && packages.forEach(function (pck) {
          fetchVersions(pck).then(function (serverVersions) {
            if (typeof packageVersions[pck] !== 'string') {
              console.error("Cannot check version: Invalid package version for package ".concat(pck, " -> ").concat(packageVersions[pck]));
              return;
            }

            if (typeof serverVersions.version !== 'string') {
              console.error("Cannot check version: Invalid serverVersions.version -> ".concat(serverVersions.version));
              return;
            }

            if (serverVersions.version && (0, _semverCompare.default)(serverVersions.version, packageVersions[pck]) > 0) {
              setPackagesWithNewVersion(function (state) {
                return [].concat((0, _toConsumableArray2.default)(state), [pck]);
              });
            }

            if ((0, _semverCompare.default)(serverVersions.latestStableVersion && serverVersions.latestStableVersion, packageVersions[pck]) > 0) {
              setPackagesWithNewStableVersion(function (state) {
                return [].concat((0, _toConsumableArray2.default)(state), [pck]);
              });
            }
          });
        });
      }, UPDATE_INTERVAL);
    };

    setIntervalId(reloadVersions(Object.keys(packagesWithRoutes)));
    return clearInterval(intervalId); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children;
};

exports.ReloadOnVersionChange = ReloadOnVersionChange;