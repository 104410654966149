import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import {getLocale} from '@jobmatchme/ui/es/utils/functions'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: getLocale(),
    fallbackLng: 'de',
    resources: {
      de: {
        'profile-properties': {
          common: require('@jobmatchme/profile-components/es/locales/de/commonProfile.json'),
          care: require('@jobmatchme/profile-components/es/locales/de/careProfile.json'),
          truck: require('@jobmatchme/profile-components/es/locales/de/truckProfile.json'),
          'service-gastro': require('@jobmatchme/profile-components/es/locales/de/serviceGastroProfile.json'),
          'logistics-scheduler': require('@jobmatchme/profile-components/es/locales/de/logisticsSchedulerProfile.json')
        },
        ui: require('@jobmatchme/ui/es/locales/de/ui.json'),
        'bc-dashboard': {
          ...require('@jobmatchme/bc-dashboard/es/locales/de/bc-dashboard.json')
        },
        'pc-matching': {
          ...require('@jobmatchme/pc-matching/es/locales/de/common.json')
        },
        'profile-components': {
          ...require('@jobmatchme/profile-components/es/locales/de/profile-components.json')
        },
        'landingpage-components': {
          ...require('@jobmatchme/landing-page-components/es/locales/de/common.json')
        },

        'pc-funnel': {
          funnel: require('@jobmatchme/pc-funnel/es/locales/de/pc-funnel.json'),
          common: require('@jobmatchme/pc-funnel/es/locales/de/commonFunnelSteps.json'),
          care: require('@jobmatchme/pc-funnel/es/locales/de/careFunnelSteps.json'),
          truck: require('@jobmatchme/pc-funnel/es/locales/de/truckFunnelSteps.json'),
          'logistics-scheduler': require('@jobmatchme/pc-funnel/es/locales/de/logisticsSchedulerFunnelSteps.json'),
          'service-gastro': require('@jobmatchme/pc-funnel/es/locales/de/serviceGastroFunnelSteps.json'),
          registration: require('@jobmatchme/pc-funnel/es/locales/de/registrationSteps.json'),
          ...require('@jobmatchme/pc-funnel/es/locales/de/generics.json')
        }
      },
      pl: {
        'profile-properties': {
          common: require('@jobmatchme/profile-components/es/locales/pl/commonProfile.json'),
          //care: require('@jobmatchme/profile-components/es/locales/de/careProfile.json'),
          truck: require('@jobmatchme/profile-components/es/locales/pl/truckProfile.json')
          //'logistics-scheduler': require('@jobmatchme/profile-components/es/locales/de/logisticsSchedulerProfile.json')
        },
        ui: require('@jobmatchme/ui/es/locales/de/ui.json'),
        'bc-dashboard': {
          ...require('@jobmatchme/bc-dashboard/es/locales/pl/bc-dashboard.json')
        },
        'pc-matching': {
          ...require('@jobmatchme/pc-matching/es/locales/de/common.json')
        },
        'profile-components': {
          ...require('@jobmatchme/profile-components/es/locales/pl/profile-components.json')
        },
        'pc-funnel': {
          common: require('@jobmatchme/pc-funnel/es/locales/pl/commonFunnelSteps.json'),
          funnel: require('@jobmatchme/pc-funnel/es/locales/pl/pc-funnel.json'),
          //care: require('@jobmatchme/pc-funnel/es/locales/de/careFunnelSteps.json'),
          truck: require('@jobmatchme/pc-funnel/es/locales/pl/truckFunnelSteps.json'),
          //'logistics-scheduler': require('@jobmatchme/pc-funnel/es/locales/de/logisticsSchedulerFunnelSteps.json'),
          registration: require('@jobmatchme/pc-funnel/es/locales/pl/registrationSteps.json'),
          ...require('@jobmatchme/pc-funnel/es/locales/pl/generics.json')
        },
        'landingpage-components': {
          ...require('@jobmatchme/landing-page-components/es/locales/pl/common.json')
        }
      }
    },
    ns: [
      'ui',
      'bc-dashboard',
      'profile-components',
      'pc-matching',
      'profile-properties'
    ],

    react: {
      useSuspense: false
    },

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
