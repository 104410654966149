"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useResource = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

/**
 * Resource hook
 * use a resource to suspend a component based on the resource as state
 * Keeps the memory resource in sync with the state
 * @param {Resource} resource the prepared resource
 * @returns
 */
var useResource = function useResource(resource) {
  var _useState = (0, _react.useState)(resource.read()),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  var setResource = (0, _react.useCallback)(function (newResource) {
    resource.setResource(newResource).catch(function () {
      setState(resource.read());
    });
    setState(resource.read());
  }, [resource]);
  var setLocalResource = (0, _react.useCallback)(function (newResource) {
    resource.setLocalResource(newResource);
    setState(resource.read());
  }, [resource]);
  return [state, setResource, setLocalResource];
};

exports.useResource = useResource;